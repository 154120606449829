import { useShoppingBagStore } from '~/store/shoppingBagStore'
import { useNewSettingsStore } from '~/store/newSettingsStore'
import useOrderStore from '~/store/order'

import { salutations } from '@/constants/utilConstants'
import { POST_PAYMENT_PAGES_ROUTES } from '~/constants/routes'
import { ORDER_TYPES } from '~/constants/order'

//ToDo: google analytics
const gglTrack = {
  UpdateCountry: () => {},
  UpdateCurrency: () => {},
  BasketCheckoutPageView: () => {},
}

export enum CheckoutSteps {
  Checkout,
  Details,
  Payment,
}

export const useCheckoutStore = defineStore('checkout', () => {
  const {
    initCheckoutData,
    getFinalFinanceOption,
    saveOrderDetails,
    getAddressByPostCode,
    payWithCard,
    payWithPaypal,
    payWithSplitit,
    payWithBankTransfer,
    getSuccessData,
  } = useCheckout()
  const orderStore = useOrderStore()
  const { Items: bagItems, OrderPrice: bagOrderPrice, IncludeVat: bagIncludeVat } = storeToRefs(useShoppingBagStore())
  const { currentCountry, currentCurrency, currentLanguage, userPreferences } = storeToRefs(useNewSettingsStore())
  const checkoutStep = ref(CheckoutSteps.Checkout)
  const paymentErrorStep3 = ref('')
  const runtimeConfig = useRuntimeConfig()
  const currentUrl = useRequestURL()
  const user = ref({
    Title: 1,
    FirstName: null,
    LastName: null,
    Gender: '',
    Email: null,
    PhoneCode: null,
    PhoneNumber: null,
    MarketingOption: false,
    DeliveryOption: 'BillingAddress',
    CollectionPlace: null,
    BillingAddress: {
      PostCode: '',
      Address1: null,
      Address2: '',
      City: '',
      County: '',
      CountryId: userPreferences.value?.CountryId || null,
    },
    DeliveryAddress: {
      PostCode: '',
      Address1: null,
      Address2: '',
      City: '',
      County: '',
      CountryId: userPreferences.value?.CountryId || null,
    },
    FinanceProduct: null,
    PaymentMethod: 1,
  })
  const addresses: Ref<{
    BillingAddress: string[]
    DeliveryAddress: string[]
  }> = ref({
    BillingAddress: [],
    DeliveryAddress: [],
  })
  const billingCountries = ref(null)
  const deliveryCountries = ref(null)
  const states = ref(null)
  const Items = ref(null)
  const OrderPrice = ref(null)
  const IncludeVat = ref(null)
  const discountCode = ref('') // TODO: set discount code
  const discountError = ref(false)
  const salutationsList = computed(() => {
    const { currentLanguage } = useNewSettingsStore()
    const currentSalutations = salutations.filter((s) => s.languageId == currentLanguage)
    if (!currentSalutations || currentSalutations.length == 0) return salutations.filter((s) => s.languageId == 1)
    return currentSalutations
  })
  const checkoutData = ref(null)
  const bannedCountry = ref(null)
  const EnumOrderType = ref({
    OnlineCreditCard: 1,
    OnlinePayPal: 2,
    OnlineGoogleCheckOut: 3,
    Phone: 4,
    BankTransfer: 5,
    Manual: 6,
    OnlineFinance: 7,
    Resize: 9,
    Repair: 10,
    Amazon: 11,
    BuyBack: 12,
    Return: 13,
    ManualOnsite: 14,
    ManualEmail: 15,
    SplitIt: 17,
    ApplePrimer: 18,
    VoltPrimer: 19,
  })
  const askForGender = ref(null)
  const financeOption = ref({})
  const financeProducts = ref([])
  const applePrimerAvailable = ref(false)
  const voltPrimerAvailable = ref(false)
  const loggedUser = ref(null)

  //todo implement only when payment has been implemented
  // const isStripeOrderConfirmation = CONFIRMATION_ORDER_PARAMETERS.stripe
  const isStripeOrderConfirmation = false
  const isAmazonOrderConfirmation = false

  // Getters
  const prepareCheckoutData = computed(() => {
    const model = user.value

    if (!model.PaymentMethod) {
      if (paymentAvailable.value.OnlineCreditCard) {
        model.PaymentMethod = EnumOrderType.value.OnlineCreditCard
      } else if (paymentAvailable.value.OnlinePayPal) {
        model.PaymentMethod = EnumOrderType.value.OnlinePayPal
      } else {
        model.PaymentMethod = EnumOrderType.value.OnlineGoogleCheckOut
      }
    }

    let financeChosenProduct = null

    if (user.value.PaymentMethod == EnumOrderType.value.OnlineFinance) {
      const product = financeProducts.value.find((p) => p.ProductId == user.value.FinanceProduct)
      financeChosenProduct = product?.ProductGuid
    }

    const DeliveryAddress = {
      PostCode: model.DeliveryAddress.PostCode,
      Address1: model.DeliveryAddress.Address1,
      Address2: model.DeliveryAddress.Address2,
      City: model.DeliveryAddress.City,
      County: model.DeliveryAddress.County,
      CountryId: model.DeliveryAddress.CountryId,
    }

    const BillingAddress = {
      PostCode: model.BillingAddress.PostCode,
      Address1: model.BillingAddress.Address1,
      Address2: model.BillingAddress.Address2,
      City: model.BillingAddress.City,
      County: model.BillingAddress.County,
      CountryId: model.BillingAddress.CountryId,
    }

    const BlankAddress = {
      PostCode: '',
      Address1: '',
      Address2: '',
      City: '',
      County: '',
      CountryId: -1,
    }

    return {
      checkoutStep: checkoutStep.value,
      model: {
        ...model,
        BillingAddress: model.DeliveryOption == 'BillingAddress' ? DeliveryAddress : BillingAddress,
        DeliveryAddress: model.DeliveryOption == 'BillingAddress' ? BlankAddress : DeliveryAddress,
      },
      userPreference: {
        countryId: currentCountry.value?.Id,
        currencyId: currentCurrency.value?.Id,
        languageId: currentLanguage.value?.Id,
        discountCode: discountCode.value,
      },
      financeChosenProduct,
    }
  })

  const paymentAvailable = computed(() => {
    const pam = checkoutData.value?.PaymentMethodsAvailability

    return {
      OnlineCreditCard: !pam.CountriesWithoutCreditCardPaymentAvailable.includes(currentCountry.value?.Id),
      OnlinePayPal: !pam.CountriesWithoutPayPalPaymentAvailable.includes(currentCountry.value?.Id),
      OnlineGoogleCheckOut: true,
      Phone: false,
      //provisional
      BankTransfer: true,
      // BankTransfer: pam.IsBankPaymentAvailable,
      Manual: true,
      OnlineFinance: financeOption.value.FinanceEnabled && financeProducts.value.length > 0,
      Resize: true,
      Repair: true,
      Amazon: true,
      BuyBack: true,
      Return: true,
      ManualOnsite: true,
      ManualEmail: true,
      SplitIt: financeOption.value.SplitItEnabled,
      applePrimer: applePrimerAvailable.value,
      voltPrimer: voltPrimerAvailable.value,
    }
  })

  const userFullName = computed(() => `${user.value.FirstName} ${user.value.LastName}`)

  const currentPaymentMethod = computed(() => user.value?.PaymentMethod ?? null)

  const isCreditCardOrPaypalPayment = computed(() =>
    [ORDER_TYPES.OnlineCreditCard, ORDER_TYPES.OnlinePayPal].includes(currentPaymentMethod.value),
  )

  const isAmazonPayment = computed(() => currentPaymentMethod.value === ORDER_TYPES.Amazon && isAmazonOrderConfirmation)

  const isBankTransferPayment = computed(() => currentPaymentMethod.value === ORDER_TYPES.BankTransfer)

  const isKlarnaPayment = computed(() => currentPaymentMethod.value === ORDER_TYPES.Klarna && isStripeOrderConfirmation)

  const isPhonePayment = computed(() => currentPaymentMethod.value === ORDER_TYPES.Phone)

  const isStripePayment = computed(
    () => currentPaymentMethod.value === ORDER_TYPES.OnlineCreditCard && isStripeOrderConfirmation,
  )

  // Actions
  const setBag = (bagContent) => {
    Items.value = bagContent.Items
    OrderPrice.value = bagContent.OrderPrice
    IncludeVat.value = bagContent.IncludeVat

    gglTrack.UpdateCountry(bagContent.PageViewTracking.Country)
    gglTrack.UpdateCurrency(bagContent.PageViewTracking.Currency)
    gglTrack.BasketCheckoutPageView(
      bagContent.PageViewTracking.Category,
      bagContent.PageViewTracking.SubCategory,
      bagContent.PageViewTracking.Name,
      bagContent.PageViewTracking.Price,
      bagContent.OrderPrice.TotalPrice.FinalPrice.NumericPrice.WithVat,
    )
  }

  const setPaymentMethod = (paymentMethod) => {
    user.value.PaymentMethod = paymentMethod
  }
  const setAddresses = ({ type, list }: { type: 'BillingAddress' | 'DeliveryAddress'; list: string[] }) => {
    addresses.value[type] = list
  }

  const updateAddress = ({ type, address }) => {
    const addressArray = address.split(',')

    user.value[type].Address1 = addressArray
      .slice(0, 5)
      .filter((s) => s != ' ')
      .join()
    user.value[type].Address2 = addressArray[6]
    user.value[type].City = addressArray[5]
  }

  const setFinanceOption = (option) => {
    financeOption.value = option
  }

  const setFinanceProducts = (products) => {
    financeProducts.value = products

    if (products && products.length) {
      user.value.FinanceProduct = products[0].ProductId
    }
  }

  const setApplePrimerAvailability = (availability) => {
    applePrimerAvailable.value = availability
  }

  const setVoltPrimerAvailability = (availability) => {
    voltPrimerAvailable.value = availability
  }

  const setUser = (userObj) => {
    loggedUser.value = userObj

    if (userObj) {
      user.value.Title = userObj.title ? salutations.find((s) => s.label == userObj.title).value : 1
      user.value.FirstName = userObj.firstName
      user.value.LastName = userObj.lastName
      user.value.Email = userObj.email
      user.value.PhoneCode = userObj.phoneCode
      user.value.PhoneNumber = userObj.phoneNumber

      if (userObj.addresses) {
        const isDefaultDelivery = userObj.addresses.find((a) => a.isDefaultDelivery)
        const isDefaultBilling = userObj.addresses.find((a) => a.isDefaultBilling)

        if (isDefaultBilling)
          user.value.BillingAddress = {
            PostCode: isDefaultBilling.postCode,
            Address1: isDefaultBilling.addressLine1,
            Address2: isDefaultBilling.addressLine2,
            City: isDefaultBilling.city,
            County: isDefaultBilling.county,
            CountryId: isDefaultBilling.countryId,
          }

        if (isDefaultDelivery)
          user.value.DeliveryAddress = {
            PostCode: isDefaultDelivery.postCode,
            Address1: isDefaultDelivery.addressLine1,
            Address2: isDefaultDelivery.addressLine2,
            City: isDefaultDelivery.city,
            County: isDefaultDelivery.county,
            CountryId: isDefaultDelivery.countryId,
          }

        if (isDefaultDelivery && isDefaultBilling && isDefaultBilling.id === isDefaultDelivery.id)
          user.value.DeliveryOption = 'BillingAddress'
        else user.value.DeliveryOption = 'DeliveryAddress'
      }
    }
  }

  const setCheckoutStep = (newStep: CheckoutSteps) => {
    checkoutStep.value = newStep
  }

  const setAddress = (addressId) => {
    if (!loggedUser.value || !loggedUser.value.addresses || !loggedUser.value.addresses.length || !addressId) return

    const address = loggedUser.value.addresses.find((a) => a.id === addressId)

    if (!address) return
    const isDefaultBilling = loggedUser.value.addresses.find((a) => a.isDefaultBilling)

    user.value.Title = address.title ? salutations.find((s) => s.label == address.title).value : 1
    user.value.FirstName = address.firstName
    user.value.LastName = address.lastName
    user.value.PhoneCode = address.phoneCode
    user.value.PhoneNumber = address.phoneNumber

    if (!address.isDefaultBilling || !address.isDefaultDelivery) {
      user.value.DeliveryOption = 'DeliveryAddress'

      user.value.DeliveryAddress = {
        PostCode: address.postCode,
        Address1: address.addressLine1,
        Address2: address.addressLine2,
        City: address.city,
        County: address.county,
        CountryId: address.countryId,
      }

      if (isDefaultBilling) {
        user.value.BillingAddress = {
          PostCode: isDefaultBilling.postCode,
          Address1: isDefaultBilling.addressLine1,
          Address2: isDefaultBilling.addressLine2,
          City: isDefaultBilling.city,
          County: isDefaultBilling.county,
          CountryId: isDefaultBilling.countryId,
        }
      }

      return
    } else if (address.isDefaultDelivery && address.isDefaultBilling) {
      const addressToSet = {
        PostCode: address.postCode,
        Address1: address.addressLine1,
        Address2: address.addressLine2,
        City: address.city,
        County: address.county,
        CountryId: address.countryId,
      }

      user.value.BillingAddress = addressToSet
      user.value.DeliveryAddress = addressToSet
      user.value.DeliveryOption = 'BillingAddress'
    }
  }

  const getFinanceOption = async () => {
    const response = await getFinalFinanceOption({
      countryId: currentCountry.value?.Id,
      currencyId: currentCurrency.value?.Id,
      languageId: currentLanguage.value?.Id,
      discountCode: discountCode.value,
    })
    setFinanceOption(response)
  }

  const saveDetails = async () => {
    await saveOrderDetails(prepareCheckoutData.value)
    checkoutStep.value = CheckoutSteps.Details
  }

  const getAddress = async (type) => {
    const result = await getAddressByPostCode(user.value[type].PostCode).catch()

    if (result && (result.Addresses || []).length) {
      setAddresses({ type, list: result.Addresses })
      updateAddress({ type, address: result.Addresses[0] })
    } else {
      return false
    }

    return result
  }

  const saveAddress = async () => {
    await saveOrderDetails(prepareCheckoutData.value)
    checkoutStep.value = CheckoutSteps.Payment
  }

  const checkoutWithPaypal = async () => {
    setPaymentMethod(EnumOrderType.value.OnlinePayPal)
    const { Url: paypalPaymentPage, Token } = await payWithPaypal({
      //ToDo: investigate callback urls
      paypalCallBackUrl: `${runtimeConfig.public.baseApiUrl}/api/v1/payments/paypal/callback`,
      paypalReturnUrl: currentUrl.toString(),
      paypalCancelUrl: currentUrl.toString(),
      model: prepareCheckoutData.value.model,
      userPreference: prepareCheckoutData.value.userPreference,
    })

    window.location.href = paypalPaymentPage
  }

  const checkoutWithSplitit = async () => {
    const splititPaymentUrl = await payWithSplitit({
      model: prepareCheckoutData.value.model,
      userPreference: prepareCheckoutData.value.userPreference,
      callbackUrl: currentUrl.toString(),
    })

    window.location.href = splititPaymentUrl
  }

  const checkoutCardPayment = (token) => {
    return payWithCard({
      model: prepareCheckoutData.value.model,
      userPreference: prepareCheckoutData.value.userPreference,
      token,
    })
  }

  const checkoutWithBankTransfer = async () => {
    const status = await payWithBankTransfer({
      model: prepareCheckoutData.value.model,
      userPreference: prepareCheckoutData.value.userPreference,
    })

    if (status === 'success') {
      navigateTo(POST_PAYMENT_PAGES_ROUTES.SUCCESS)
    }
  }

  const initCheckout = async () => {
    const payload = {
      userPreference: {
        countryId: currentCountry.value?.Id,
        currencyId: currentCurrency.value?.Id,
        languageId: currentLanguage.value?.Id,
        discountCode: discountCode.value,
      },
    }

    const response = await initCheckoutData(payload)
    if (!response.BillingCountries) return

    checkoutData.value = response
    billingCountries.value = response.BillingCountries
    deliveryCountries.value = response.DeliveryCountries
    states.value = response.States
    Items.value = response.ShoppingBag.Items
    OrderPrice.value = response.ShoppingBag.OrderPrice
    IncludeVat.value = response.ShoppingBag.IncludeVat
  }

  const initSuccess = async () => {
    const payload = {
      userPreference: {
        countryId: currentCountry.value?.Id,
        currencyId: currentCurrency.value?.Id,
        languageId: currentLanguage.value?.Id,
        discountCode: discountCode.value,
      },
    }

    const response = await getSuccessData(payload)

    if (response?.OrderId) {
      orderStore.setOrderData(response)
    }
  }

  return {
    checkoutStep,
    paymentErrorStep3,
    user,
    addresses,
    Items,
    OrderPrice,
    IncludeVat,
    discountCode,
    discountError,
    salutationsList,
    checkoutData,
    billingCountries,
    deliveryCountries,
    states,
    bannedCountry,
    EnumOrderType,
    currentCountry,
    askForGender,
    financeOption,
    financeProducts,
    applePrimerAvailable,
    voltPrimerAvailable,
    loggedUser,
    prepareCheckoutData,
    paymentAvailable,
    userFullName,
    isCreditCardOrPaypalPayment,
    isAmazonPayment,
    isBankTransferPayment,
    isKlarnaPayment,
    isPhonePayment,
    isStripePayment,
    initCheckout,
    initSuccess,
    setBag,
    setPaymentMethod,
    setAddresses,
    updateAddress,
    setFinanceOption,
    setFinanceProducts,
    setApplePrimerAvailability,
    setVoltPrimerAvailability,
    setUser,
    setAddress,
    getFinanceOption,
    saveDetails,
    getAddress,
    saveAddress,
    checkoutWithPaypal,
    checkoutWithSplitit,
    checkoutCardPayment,
    checkoutWithBankTransfer,
    setCheckoutStep,
  }
})
