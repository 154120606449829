interface UserPreferences {
  countryId: number | undefined
  currencyId: number | undefined
  languageId: number | undefined
  discountCode: string | undefined
}

interface SaveOrderDetailsParams {
  model: any
  checkoutStep: number
  userPreference: UserPreferences
}

interface PayWithCardParams {
  model: any
  userPreference: UserPreferences
  token?: string
}

export const useCheckout = () => {
  const initCheckoutData = (payload: { userPreference: UserPreferences }) => {
    return fetchWithLoader('/api/checkout/process-page', {
      method: 'POST',
      body: payload,
    })
  }

  const getFinalFinanceOption = (userPreferences: UserPreferences) => {
    return fetchWithLoader('/api/checkout/finance-option', {
      method: 'POST',
      body: JSON.stringify(userPreferences),
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }

  const saveOrderDetails = ({ model, checkoutStep, userPreference }: SaveOrderDetailsParams) => {
    return fetchWithLoader('/api/checkout/save-step', {
      method: 'POST',
      body: JSON.stringify({
        model,
        userPreference,
        step: checkoutStep + 1,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }

  const payWithCard = ({ model, userPreference, token }: PayWithCardParams) => {
    return fetchWithLoader('/api/checkout/payment-url', {
      method: 'POST',
      body: JSON.stringify({
        model,
        userPreference,
        checkoutToken: token,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }

  const payWithBankTransfer = ({ model, userPreference }: PayWithCardParams) => {
    return fetchWithLoader('/api/checkout/payment-bank-transfer', {
      method: 'POST',
      body: JSON.stringify({
        model,
        userPreference,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }

  const payWithSplitit = ({ model, userPreference, callbackUrl }: PayWithCardParams & { callbackUrl: string }) => {
    return fetchWithLoader('/api/checkout/payment-splitit', {
      method: 'POST',
      body: JSON.stringify({
        model,
        userPreference,
        callbackUrl,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }

  const getSuccessData = (payload: { userPreference: UserPreferences }) => {
    return fetchWithLoader('/api/checkout/success', {
      method: 'POST',
      body: payload,
    })
  }

  const payWithPaypal = ({
    model,
    userPreference,
    paypalCallBackUrl,
    paypalReturnUrl,
    paypalCancelUrl,
  }: PayWithCardParams & {
    paypalCallBackUrl: string
    paypalReturnUrl: string
    paypalCancelUrl: string
  }) => {
    return fetchWithLoader('/api/checkout/payment-paypal', {
      method: 'POST',
      body: JSON.stringify({
        model,
        userPreference,
        paypalCallBackUrl,
        paypalReturnUrl,
        paypalCancelUrl,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }

  const getAddressByPostCode = (postcode: string) => {
    return fetchWithLoader(`https://api.getAddress.io/v2/uk/${postcode}?api-key=yP6-zqoRkkKiu1HtBrbMGg6164`, {
      method: 'GET',
    })
  }

  const checkoutProceedPayment = (payload: any, service: string = '/pay') => {
    return fetchWithLoader(`/api/v1/${service}`, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }

  return {
    getFinalFinanceOption,
    saveOrderDetails,
    getAddressByPostCode,
    getSuccessData,
    payWithCard,
    checkoutProceedPayment,
    payWithPaypal,
    payWithSplitit,
    payWithBankTransfer,
    initCheckoutData,
  }
}
